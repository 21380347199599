@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: normal;
  font-weight: 100;
  src: url('platform/Platform-Hairline.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: italic;
  font-weight: 100;
  src: url('platform/Platform-HairlineItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: normal;
  font-weight: 300;
  src: url('platform/Platform-Light.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: italic;
  font-weight: 300;
  src: url('platform/Platform-LightItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: normal;
  font-weight: 400;
  src: url('platform/Platform-Regular.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: italic;
  font-weight: 400;
  src: url('platform/Platform-RegularItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: normal;
  font-weight: 500;
  src: url('platform/Platform-Medium.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: italic;
  font-weight: 500;
  src: url('platform/Platform-MediumItalic.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: normal;
  font-weight: 700;
  src: url('platform/Platform-Bold.woff2') format('woff2');
}

@font-face {
  font-display: swap;
  font-family: 'Platform';
  font-style: italic;
  font-weight: 700;
  src: url('platform/Platform-BoldItalic.woff2') format('woff2');
}
