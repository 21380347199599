.wysiwyg {
  .wysiwyg-alternate-blocks {
    @apply my-12 first:mt-0 last:mb-0;

    .wysiwyg-grid {
      @apply gap-y-6;

      @media screen(md-more) {
        --container-columns: 10;
      }

      &:nth-child(even) {
        .wysiwyg-alternate-media {
          @apply md-more:order-2 md-more:col-start-6 lg-more:col-start-7;
        }
      }

      &:nth-child(odd) {
        .wysiwyg-alternate-content {
          @apply md-more:col-start-6;
        }
      }
    }

    .wysiwyg-alternate-media {
      @apply col-end-[span_4] md:col-end-[span_5] sm:col-span-4;

      figure, iframe, .embed-responsive {
        @apply w-full;
      }
    }

    .wysiwyg-alternate-content {
      @apply col-end-[span_5] sm:col-span-4;
    }

    .wysiwyg-lead {
      @apply text-brand3-500;
    }
  }
}
