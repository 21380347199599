.wysiwyg {
  @apply [--wysiwyg-width:68rem] w-full max-w-[--wysiwyg-width] mx-auto p-0;

  cite {
    @apply not-italic;
  }

  h2 {
    @apply text-brand1-500;
    font-size: 3.5rem;
    line-height: 1.3;
    letter-spacing: normal;

    @media screen(lg) {
      font-size: 2.75rem;
    }

    @media screen(sm) {
      font-size: 2rem;
    }
  }

  h3 {
    @apply text-brand1-500;
    font-size: 3rem;
    line-height: 1.3;
    letter-spacing: normal;

    @media screen(lg) {
      font-size: 2.25rem;
    }

    @media screen(sm) {
      font-size: 1.8125rem;
    }
  }

  h4 {
    @apply text-brand1-500;
    font-size: 2.125rem;
    line-height: 1.3;

    @media screen(lg) {
      font-size: 1.875rem;
    }

    @media screen(sm) {
      font-size: 1.625rem;
    }
  }

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + *,
  p + *,
  ul + *,
  ol + *,
  blockquote + *,
  figure + *,
  pre + *,
  table + *,
  dl + *,
  address + *,
  div + p,
  div + blockquote,
  div + table,
  div + ul,
  div + ol,
  div + dl,
  div + pre,
  div + h1,
  div + h2,
  div + h3,
  div + h4,
  div + h5,
  div + h6,
  div + figure {
    @apply mt-6;
  }

  a {
    @apply text-brand2-500 underline;
  }

  ol, ul {
    &:not(.wysiwyg-summary-list) {
      @apply space-y-1;

      & > li {
        @apply relative;

        & > ol,
        & > ul {
          @apply mt-1;
        }
      }

      ol > li {
        @apply before:content-[counter(list,lower-alpha)];
      }
    }
  }

  ol {
    &:not(.wysiwyg-summary-list) {
      counter-reset: list;

      & > li {
        @apply pl-[1.625rem] before:content-[counter(list)] before:absolute before:top-[0.3125rem] before:left-0 before:h-[1.125rem] before:w-[1.125rem] before:grid before:place-items-center before:rounded-full before:bg-brand2-300 before:text-xs before:text-black;
        counter-increment: list;
      }
    }
  }

  ul {
    &:not(.wysiwyg-summary-list) {
      & > li {
        @apply pl-4 before:absolute before:top-[0.55555em] before:left-0 before:h-2 before:w-2 before:rounded-full before:bg-brand2-500;
      }
    }
  }

  hr {
    --separator-dashed-size: 4px;
    --separator-dashed-spacing: calc(16px - var(--separator-dashed-size));
    @apply h-[--separator-dashed-size] my-10 bg-left-top bg-repeat-x bg-[length:var(--separator-dashed-spacing)_var(--separator-dashed-size)] border-0 text-brand1-500;
    background-image: radial-gradient(circle at calc(var(--separator-dashed-size) * 0.5), currentColor calc(var(--separator-dashed-size) * 0.5), rgba(255,255,255,0) calc(var(--separator-dashed-size) * 0.5));
  }

  figure {
    @apply relative w-[var(--fig-width,auto)] max-w-full my-12 first:mt-0 last:mb-0;

    &.wysiwyg-float-left,
    &.wysiwyg-float-right {
      @apply mt-6 first:mt-0;
    }

    & > img,
    & > iframe,
    & > pre,
    & > div {
      vertical-align: middle;
    }

    img, iframe {
      @apply !max-w-full;
    }

    iframe {
      @apply block;
    }

    figcaption {
      @apply max-w-full pl-2 mt-4 italic text-left border-l-8 border-brand2-500;
      font-size: 1rem;
      letter-spacing: 0.02em;
      line-height: 1.5;
    }
  }

  .wysiwyg-image {
    @apply relative block w-full max-w-full;

    img {
      @apply w-full;
    }
  }

  .wysiwyg-credit-badge {
    @apply absolute top-auto bottom-3 left-[calc(100%-0.75rem)] origin-left rotate-180 bg-white/80 ps-1 pe-1 rounded-sm text-xxs text-body-300 whitespace-nowrap text-ellipsis overflow-hidden;
    padding-block-start: 0.1875rem;
    padding-block-end: 0.1875rem;
    max-inline-size: calc(100% - 1.5rem);
    writing-mode: vertical-lr;
  }

  blockquote {
    @apply w-full max-w-[40rem] mx-auto my-12 text-center text-brand2-500 first:mt-0 last:mb-0;

    &::before, &::after {
      @apply content-[''] block h-[3.375rem] w-[4.625rem] bg-contain bg-center bg-no-repeat sm:h-8 sm:w-11;
    }

    &::before {
      @apply ml-[1.125rem] mb-6 sm:mx-auto sm:mb-[2.375rem];
      background-image: url('application/pictures/open-quote.svg');
    }

    &::after {
      @apply ml-auto mr-[1.125rem] mt-6 sm:mx-auto sm:mt-[2.375rem];
      background-image: url('application/pictures/close-quote.svg');
    }

    p {
      font-size: 2.125rem;
      line-height: 1.3;

      @media screen(sm) {
        font-size: 1.875rem;
      }

      & + p {
        @apply mt-4 sm:mt-2;
        font-size: 1.25rem;
        line-height: 1.3;
      }
    }
  }

  .wysiwyg-align-center {
    @apply mx-auto text-center;
    max-width: 90%;
  }

  .wysiwyg-float-left, .wysiwyg-float-right {
    @apply mb-3;
    max-width: 50%;
  }

  .wysiwyg-float-left {
    @apply mr-[--container-gutter];
    float: left;
  }

  .wysiwyg-float-right {
    @apply ml-[--container-gutter];
    float: right;
  }

  .align-left {
    @apply text-left;
  }

  .align-right {
    @apply text-right;

    .wysiwyg-image {
      @apply ml-auto;
    }

    figcaption {
      @apply ml-auto text-right;
    }
  }

  .align-justify {
    @apply text-justify;
  }

  .align-center {
    @apply text-center;
  }

  .valign-top {
    align-items: start;
  }

  .valign-middle {
    align-items: center;
  }

  .valign-bottom {
    align-items: end;
  }

  .embed-responsive {
    @apply relative h-0 m-0 p-0 pt-[25px] pb-[56.25%];

    iframe {
      @apply absolute top-0 left-0 h-full w-full;
    }
  }

  .wysiwyg-grid {
    @apply grid grid-cols-container gap-x-[--container-gutter] gap-y-12 my-12 first:mt-0 last:mb-0;

    .column-1 {
      @apply col-end-[span_1];
    }

    .column-2 {
      @apply col-end-[span_2];
    }

    .column-3 {
      @apply col-end-[span_3];
    }

    .column-4 {
      @apply col-end-[span_4];
    }

    .column-5 {
      @apply col-end-[span_5];
    }

    .column-6 {
      @apply col-end-[span_6];
    }

    .column-7 {
      @apply col-end-[span_7];
    }

    .column-8 {
      @apply col-end-[span_8];
    }

    .column-9 {
      @apply col-end-[span_9];
    }

    .column-10 {
      @apply col-end-[span_10];
    }

    .column-11 {
      @apply col-end-[span_11];
    }

    .column-12 {
      @apply col-end-[span_12];
    }
  }
}
