.wysiwyg {
  .wysiwyg-summary-title {
    @apply font-bold;
  }

  .wysiwyg-summary-list {
    @apply mt-4 text-m text-brand1-500;
    counter-reset: summary-list;

    ol, ul {
      & > li {
        @apply before:!content-none;
      }
    }

    li {
      @apply relative pl-6 before:content-[counter(summary-list)'.'] before:absolute before:left-0 before:w-6 before:pr-1 before:text-right;
      counter-increment: summary-list;
    }

    a {
      @apply text-current no-underline hover:underline;
    }
  }
}
