.search-filters-head {
  @apply mb-4;
}

.search-filters-list {
  @apply flex flex-wrap min-w-0 -mx-1 -my-2;
}

.search-filters-item {
  @apply max-w-full px-1 py-2;
}

.search-filters-button {
  @apply mt-4;
}
