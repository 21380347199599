.cover-hero {
  @apply relative bg-brand1-500 text-white overflow-hidden md-more:h-[57.5rem];
}

.cover-hero-media {
  @apply absolute z-[1] inset-0;

  &:not(.has-custom-overlay) {
    @apply after:absolute after:inset-0 after:bg-[radial-gradient(ellipse_at_0%_0%,rgba(0,0,0,0.3),rgba(0,0,0,0.15))];
  }

  img, video {
    @apply z-[1] h-full w-full object-cover;
  }

  .credit-badge {
    @apply z-[2];
  }
}

.cover-hero-container {
  @apply relative z-[2] grid grid-cols-container gap-x-[--container-gutter] w-full max-w-[--container-width] mx-auto px-[--container-offset] py-16 sm:py-[3.375rem];
}

.cover-hero-content {
  @apply col-start-1 col-end-12 lg:col-end-[-1];
}

.cover-hero-title {
  @apply text-[8.75rem] leading-none font-light lg:text-[6.75rem] md:text-[5.25rem] sm:text-[min(11.8vw,4.6875rem)];

  b, strong {
    @apply font-normal;
  }
}

.cover-hero-description {
  @apply max-w-[54rem] title-4 mt-[3.75rem] sm:mt-7;
}

.main-hero {
  @apply py-16 bg-brand1-500 text-white sm:py-12;
}

.main-hero-container {
  @apply grid grid-cols-container gap-x-[--container-gutter] items-start w-full max-w-[--container-width] mx-auto px-[--container-offset];
}

.main-hero-content {
  @apply z-[2] col-start-2 col-end-11 md-more:sticky md-more:top-[calc(var(--header-height)+3.5rem)] md:col-start-1 sm:col-end-5;

  .main-hero.main-hero--image & {
    @apply md-more:col-end-8;

    .main-hero-title {
      @apply md-more:w-[152.5%];
    }
  }
}

.main-hero-title {
  @apply title-1;
}

.main-hero-description {
  @apply title-5 mt-12 sm:mt-4;
}

.main-hero-image {
  @apply z-[1] relative col-span-5 sm:col-start-1 sm:col-end-5;

  &.main-hero-image--landscape {
    --aspect-ratio: 592 / 406;

    :where(figure) {
      @apply w-[calc(100%+var(--container-offset))] sm:w-[16.75rem] sm:ml-auto;
    }
  }

  &.main-hero-image--portrait {
    --aspect-ratio: 464 / 592;

    :where(figure) {
      @apply w-[29rem] max-w-full sm:w-[16.75rem] ml-auto;
    }
  }

  :where(figure) {
    @apply relative mt-24 before:block before:pb-aspect-ratio sm:mt-4;
  }

  :where(img) {
    @apply absolute top-0 left-0 h-full w-full object-cover;
  }
}
