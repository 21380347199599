.btn {
  @apply inline-flex justify-center items-center font-normal;

  &:not(.btn-custom, .btn-fill, .btn-outline, .btn-underline, .btn-with-circle) {
    @apply border-transparent transition-[border-color] duration-150;

    &:where(:not(:disabled)) {
      @apply hover:border-b-current;
    }

    &.btn-xs, &.btn-s {
      @apply border-y-2;
    }

    &.btn-m, &.btn-l {
      @apply border-y-[3px];
    }
  }

  &:not(.btn-custom) {
    @apply outline-none;
  }

  :where(.btn-icon) {
    @apply flex-none inline-flex justify-center items-center;
  }
}

.btn-fill, .btn-outline {
  @apply bg-[--btn-bg-color] border rounded-full text-[--btn-text-color] transition-colors duration-150;
}

.btn-fill {
  @apply border-[var(--btn-border-color,var(--btn-bg-color))];
}

.btn-outline {
  @apply border-[var(--btn-border-color,currentColor)];

  &:where(.btn-alert-600) {
    @apply [--btn-text-color:theme(colors.alert.600)];
  }

  &:where(.btn-body-500) {
    @apply [--btn-text-color:theme(colors.body.500)];
  }

  &:where(.btn-brand1-500) {
    @apply [--btn-text-color:theme(colors.brand1.500)];
  }

  &:where(.btn-brand2-500) {
    @apply [--btn-text-color:theme(colors.brand2.500)];
  }

  &:where(.btn-white) {
    @apply [--btn-text-color:theme(colors.white)];
  }
}

.btn-fill, .btn-outline:where(:hover, :focus):where(:not(:disabled)) {
  &:where(.btn-alert-600) {
    @apply [--btn-bg-color:theme(colors.alert.600)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-body-500) {
    @apply [--btn-bg-color:theme(colors.body.500)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-brand1-500) {
    @apply [--btn-bg-color:theme(colors.brand1.500)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-brand2-500) {
    @apply [--btn-bg-color:theme(colors.brand2.500)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-white) {
    @apply [--btn-bg-color:theme(colors.white)] [--btn-text-color:theme(colors.body.500)];
  }
}

.btn-fill:where(:hover, :focus):where(:not(:disabled)) {
  &:where(.btn-alert-600) {
    @apply [--btn-bg-color:theme(colors.alert.700)];
  }

  &:where(.btn-body-500) {
    @apply [--btn-bg-color:theme(colors.body.600)];
  }

  &:where(.btn-brand1-500) {
    @apply [--btn-bg-color:theme(colors.brand1.600)];
  }

  &:where(.btn-brand2-500) {
    @apply [--btn-bg-color:theme(colors.brand2.600)];
  }

  &:where(.btn-white) {
    @apply [--btn-bg-color:theme(colors.brand6.100)];
  }
}

.btn-outline:where(:hover, :focus):where(:not(:disabled)) {
  @apply border-[var(--btn-border-color,var(--btn-bg-color))];
}

.btn-underline {
  @apply border-transparent border-b-current transition-[border] duration-200;

  &.btn-xs, &.btn-s {
    @apply border-y-2;

    &:where(:not(:disabled)) {
      @apply hover:border-y-4;
    }
  }

  &.btn-m, &.btn-l {
    @apply border-y-[3px];

    &:where(:not(:disabled)) {
      @apply hover:border-y-[5px];
    }
  }
}

.btn-xs {
  @apply h-9 px-1 text-s;

  :where(.btn-icon) {
    @apply h-4 w-4 first:mr-1 last:ml-1;
  }
}

.btn-s {
  @apply h-11 px-3 text-s;

  :where(.btn-icon) {
    @apply h-5 w-5 first:mr-1 last:ml-1;
  }
}

.btn-m {
  @apply h-14 px-4 text-m;

  :where(.btn-icon) {
    @apply h-6 w-6 first:mr-1 last:ml-1;
  }
}

.btn-l {
  @apply h-16 px-4 text-l;

  :where(.btn-icon) {
    @apply h-6 w-6 first:mr-2 last:ml-2;
  }
}

.btn-with-circle {
  @apply relative isolate text-left;

  &.btn-s {
    @apply before:h-7 before:w-7;
  }

  &.btn-m {
    @apply before:h-9 before:w-9;
  }

  &.btn-l {
    @apply before:h-12 before:w-12;
  }

  &:hover, &:focus {
    &:where(:not(:disabled)) {
      &::before {
        @apply h-full w-full;
      }
    }
  }

  &::before {
    @apply absolute top-1/2 left-0 -translate-y-1/2 rounded-full transition-[height,width] duration-300 ease-out motion-reduce:transition-none;
    z-index: -1;
  }
}

.btn-with-arrow {
  &.btn-xs, &.btn-s, &.btn-m {
    &::after {
      @apply ml-1;
    }
  }

  &.btn-l {
    &::after {
      @apply ml-2;
    }
  }

  &::after {
    @apply content-[''] flex-none h-6 w-6 bg-current;
    -webkit-mask-image: url('application/icons/move-right.svg');
    mask-image: url('application/icons/move-right.svg');
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-size: 1.375rem 0.5rem;
    mask-size: 1.375rem 0.5rem;
  }
}
