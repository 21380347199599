.form-section {
  @apply border-t border-dashed border-brand1-500 pt-5;

  & + .form-section {
    @apply mt-11;
  }
}

.form-title {
  @apply title-4 mb-5 text-brand1-500 last:mb-0;
}

.form-subtitle {
  @apply title-6 flex justify-start items-center gap-3 mb-6 text-brand1-500;
}

.form-step-number {
  @apply grid place-content-center w-9 h-9 rounded-full bg-brand1-500 text-white;
}

.form-grid {
  @apply grid grid-cols-12 gap-x-[--container-gutter] gap-y-4;

  :where(.form-grid) {
    @apply col-span-12;
  }
}
