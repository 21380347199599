.datatable {
  :where(table) {
    @apply w-full text-m text-body-400;

    :where(thead) {
      :where(tr) {
        @apply text-left bg-body-100;
      }

      :where(th) {
        @apply py-3 px-2 font-medium whitespace-nowrap;
      }
    }

    :where(tbody tr td) {
      @apply py-3 px-2 border-t border-body-500;
    }
  }

  .sort_link {
    @apply relative block -mx-2 -my-3 py-3 pl-2 pr-8 duration-300 cursor-pointer hover:bg-body-500/10;

    &::after {
      @apply content-[''] absolute top-1/2 right-2.5 -translate-y-1/2 h-3.5 w-3.5 bg-contain bg-center;
      background-image: url('application/icons/data-transfer-both.svg');
    }

    &.asc {
      &::after {
        background-image: url('application/icons/data-transfer-up.svg');
      }
    }

    &.desc {
      &::after {
        background-image: url('application/icons/data-transfer-down.svg');
      }
    }

    svg {
      @apply text-body-500;
    }
  }
}
