.ts-wrapper {
  @apply w-full !p-0;

  &.dropdown-active {
    .ts-control {
      @apply rounded-b-none;
    }
  }

  &.single {
    &.plugin-remove_button {
      &.has-items ~ .form-icon {
        @apply hidden;
      }

      .item .remove {
        @apply z-[2] absolute top-1/2 right-0 mr-[0.6875rem] -translate-y-1/2 grid place-items-center h-6 w-6 px-0 border-0 text-body-200 text-center hover:bg-transparent hover:text-brand2-500;

        svg {
          @apply h-3 w-3;
        }
      }
    }

    .ts-control {
      @apply !pr-11;

      input[placeholder=""] {
        @apply min-w-0 h-0 flex-none;
      }
    }
  }

  &.multi {
    &.has-items {
      .ts-control {
        padding: 11px 10px 4px;
      }
    }

    &.plugin-remove_button {
      .item .remove {
        @apply flex-shrink-0 ml-0.5 mr-1 border-0 inline-flex items-center justify-center hover:bg-transparent focus:outline-none focus:bg-transparent;
      }
    }

    .ts-control {
      @apply !pr-3;
    }

    .ts-control > div {
      @apply inline-flex items-center px-[0.5625rem] mr-1.5 mb-1.5 py-1 bg-brand2-500 border border-brand2-500 rounded-lg text-s text-white;

      &.active {
        @apply bg-brand2-600 border border-brand2-600 text-white;
      }
    }

    .ts-control > input {
      @apply text-body-500;
    }
  }

  &.plugin-dropdown_input {
    .dropdown-input {
      @apply border-body-100 px-3 py-2.5 text-m text-body-500 focus:border-brand2-500 disabled:bg-grey;
    }
  }

  .dropdown-header {
    @apply border-body-100;
  }
}

.disabled .ts-control {
  @apply opacity-100 bg-grey;

  &, * {
    @apply !cursor-not-allowed;
  }
}

.ts-dropdown {
  @apply mt-0 bg-white border-body-100 rounded-b-lg text-body-500;

  .create, .no-results, .option, .optgroup-header, .dropdown-header {
    @apply px-3 py-2;
  }

  .optgroup-header {
    @apply bg-body-100/50;
  }

  .create, .no-results {
    @apply text-body-300;
  }

  .option {
    @apply flex items-center text-body-500;

    &:not([data-selectable]), &[data-disabled] {
      @apply opacity-100 text-body-300;
    }

    input[type="checkbox"] {
      @apply inline-block h-3.5 w-3.5;
    }
  }

  .active {
    @apply bg-brand2-100/50 text-body-500;
  }

  .active.create {
    @apply text-body-500;
  }

  .dropdown-header-close {
    @apply hidden;
  }

  [data-selectable] .highlight {
    @apply bg-brand2-200;
  }
}

.ts-dropdown-content {
  @apply rounded-b-lg text-m;
}
