.form-control {
  @apply relative block w-full;
}

.form-field-datetime-picker, .form-field-date-picker, .form-field-time-picker {
  :where(.input) {
    @apply pl-3;
  }
}

.form-field-check-box, .form-field-radio-button, .form-field-switch {
  @apply relative flex items-center;

  :where(.form-field-check-box-content, .form-field-radio-button-content, .form-field-switch-content) {
    @apply ml-2 text-m text-body-500;
  }
}

.form-field-switch-control {
  @apply relative flex-none rounded-full justify-center cursor-pointer;
}
