.wysiwyg {
  .wysiwyg-three-cards {
    @apply my-12 first:mt-0 last:mb-0;

    @media screen(sm) {
      --container-columns: 12;
      --container-gutter: 3rem;
      @apply -mx-[--wrapper-offset] px-[--wrapper-offset] overflow-auto;
    }

    .wysiwyg-grid {
      @media screen(sm) {
        @apply min-w-[53.4375rem];
      }
    }
  }
}
