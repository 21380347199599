/* Entry point for your PostCSS build */
@import 'tailwindcss/base';
@import 'flatpickr/dist/flatpickr';
@import 'flatpickr/dist/themes/airbnb';
@import 'swiper/swiper.min';
@import 'swiper/modules/effect-fade.min';
@import 'swiper/modules/navigation.min';
@import 'nouislider/dist/nouislider.css';
@import 'tom-select/dist/css/tom-select';
@import './application/base';

@import 'tailwindcss/components';

@import './application/components/form/base';
@import './application/components/form/layout';
@import './application/components/form/group';
@import './application/components/form/field';
@import './application/components/form/flatpickr';
@import './application/components/form/tom-select';

@import './application/components/badge';
@import './application/components/button';
@import './application/components/chip';
@import './application/components/container';
@import './application/components/cta';
@import './application/components/datatable';
@import './application/components/filter-range';
@import './application/components/hero';
@import './application/components/progress-bar';
@import './application/components/separator';
@import './application/components/tag';
@import './application/components/typography';

@import './application/components/search/bar';
@import './application/components/search/cards';
@import './application/components/search/facets';
@import './application/components/search/filters';
@import './application/components/search/form';
@import './application/components/search/layout';

@import './wysiwyg/components';

@import 'tailwindcss/utilities';

@layer utilities {
  .reverse {
    unicode-bidi: bidi-override;
    direction: rtl;
  }
}

