.wysiwyg {
  .wysiwyg-btn-primary {
    @apply relative isolate h-14 inline-flex justify-center items-center px-4 text-m text-body-500 font-normal no-underline outline-none;

    &:hover, &:focus {
      &::before {
        @apply h-full w-full;
      }
    }

    &::before {
      @apply content-[''] absolute top-1/2 left-0 -translate-y-1/2 h-9 w-9 rounded-full bg-brand2-500 transition-[height,width] duration-300 ease-out;
      z-index: -1;
    }

    &::after {
      @apply content-[''] flex-none h-6 w-6 ml-1 bg-current;
      -webkit-mask-image: url('application/icons/move-right.svg');
      mask-image: url('application/icons/move-right.svg');
      -webkit-mask-position: center;
      mask-position: center;
      -webkit-mask-repeat: no-repeat;
      mask-repeat: no-repeat;
      -webkit-mask-size: 1.375rem 0.5rem;
      mask-size: 1.375rem 0.5rem;
    }
  }

  .wysiwyg-btn-secondary {
    @apply inline-flex justify-center items-center h-9 px-2 border-y-2 border-transparent border-b-current text-s text-brand2-500 font-normal no-underline outline-none transition-[border] duration-200 hover:border-y-4;
  }
}
