.flatpickr-months {
  .flatpickr-prev-month, .flatpickr-next-month {
    &:hover {
      @apply text-brand1-500;

      svg {
        @apply fill-brand1-500;
      }
    }
  }
}

.flatpickr-current-month span.cur-month {
  @apply font-medium hover:bg-transparent;
}

span.flatpickr-weekday {
  @apply text-body-300 font-medium;
}

span.flatpickr-day {
  &.today, &.prevMonthDay.today, &.nextMonthDay.today {
    &:not(.selected) {
      @apply border-[#e9e9e9] border-b-brand1-500;

      &:hover {
        @apply border-brand3-500 border-b-brand1-500;
      }
    }
  }
}

.flatpickr-day {
  &.inRange, &.prevMonthDay.inRange, &.nextMonthDay.inRange, &.today.inRange, &.prevMonthDay.today.inRange, &.nextMonthDay.today.inRange, &:hover, &.prevMonthDay:hover, &.nextMonthDay:hover, &:focus, &.prevMonthDay:focus, &.nextMonthDay:focus {
    @apply bg-brand3-100 border-brand3-100;
  }

  &.today {
    &:hover, &:focus {
      @apply bg-brand3-500 border-brand3-500 text-white;
    }
  }

  &.selected, &.startRange, &.endRange {
    &, &.inRange, &:focus, &:hover, &.prevMonthDay, &.nextMonthDay {
      @apply bg-brand1-500 border-brand1-500 text-white;
    }
  }
}

.flatpickr-time {
  input, .flatpickr-am-pm {
    &:hover, &:focus {
      @apply bg-brand3-100;
    }
  }
}
