.search-company-card {
  @apply relative grid gap-y-4 gap-x-6 border border-body-100 p-4 rounded-lg md-more:grid-cols-[1fr_auto];
}

.search-company-card-title {
  @apply title-4 text-brand1-500;
}

.search-company-card-side {
  @apply flex flex-col md-more:row-span-2 sm:flex-row sm:items-center sm:justify-between;
}

.search-company-card-tag {
  @apply inline-block rounded-[4rem] border border-brand1-100 ml-auto px-2.5 py-[0.5625rem] text-s text-center sm:ml-0 sm:p-2 sm:text-xs;
}

.search-company-card-logos {
  @apply flex justify-end items-start space-x-2 mt-4 sm:m-0;

  .logo-ef {
    @apply h-14 w-auto sm:h-[1.875rem];
  }

  .logo-mol {
    @apply h-auto w-16 sm:w-[2.75rem];
  }
}

.search-company-card-attributes {
  @apply space-y-4 text-m text-body-300;
}

.search-company-card-link {
  @apply md-more:col-span-2;
}
