.cta {
  @apply inline-flex justify-center items-center font-normal;

  :where(.cta-icon) {
    @apply flex-none inline-flex justify-center items-center;
  }
}

.cta-circle {
  @apply rounded-full;

  &.cta-xs {
    @apply w-9 p-0;
  }

  &.cta-s {
    @apply w-11 p-0;
  }

  &.cta-m {
    @apply w-14 p-0;
  }

  &.cta-l {
    @apply w-16 p-0;
  }
}

.cta-outline {
  @apply border border-current;
}

.cta-xs {
  @apply h-9 px-2 text-s;

  :where(.cta-icon) {
    @apply h-4 w-4 first:mr-1 last:ml-1;
  }
}

.cta-s {
  @apply h-11 px-3 text-s;

  :where(.cta-icon) {
    @apply h-5 w-5 first:mr-1 last:ml-1;
  }
}

.cta-m {
  @apply h-14 px-4 text-m;

  :where(.cta-icon) {
    @apply h-6 w-6 first:mr-1 last:ml-1;
  }
}

.cta-l {
  @apply h-16 px-5 text-l;

  :where(.cta-icon) {
    @apply h-6 w-6 first:mr-2 last:ml-2;
  }
}

.more-cta {
  @apply block px-2 py-2.5 border-t border-current text-m text-center text-brand2-500 font-medium opacity-0 transition-opacity duration-200 hover:opacity-100 after:absolute after:inset-0 disabled:content-none md:opacity-100;

  &:where(:disabled, .disabled) {
    @apply pointer-events-none after:content-none;
  }
}
