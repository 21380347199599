.search-facets {
  @apply relative overflow-hidden md:z-[1100] md:fixed md:top-0 md:bottom-0 md:right-0 md:w-[19rem] md:overflow-auto;
}

.search-facets-trigger {
  @apply z-[1100] fixed bottom-4 right-4 grid place-items-center h-[3.75rem] w-[3.75rem] border-[0.625rem] border-brand2-500 bg-brand2-100 rounded-full text-body-500 lg-more:hidden;
}

.search-facets-backdrop {
  @apply z-[1100] fixed inset-0 bg-black/30 transition-opacity lg-more:hidden;
}

.search-facets-head {
  @apply mb-8;
}

.search-facets-list {
  @apply space-y-8;
}

.search-facets-item-title {
  @apply title-6 mb-4;
}

.search-facets-item-fields {
  @apply space-y-4;
}

.search-facets-item-field {
  @apply flex;

  .form-group {
    @apply flex-1;
  }
}

.search-facets-item-more {
  @apply mt-4;
}

.search-facets-item-open {
  @apply grid place-items-center h-6 w-6 flex-none ml-3 rounded-full border border-brand1-100 bg-white text-s text-body-500 hover:bg-brand1-100;
}

.search-facets-item-drawer {
  @apply z-10 absolute top-0 right-0 h-full w-[calc(100%-1.5rem)] p-4 bg-brand3-100 overflow-auto md:fixed md:w-[17.5rem];
}

.search-facets-item-drawer-close {
  @apply mb-4 px-0 text-body-400 hover:border-b-brand2-500;

  .cta-icon {
    @apply mr-3;
  }
}

.search-facets-item-drawer-title {
  @apply title-6 mb-4 text-brand1-500;
}

.search-facets-item-drawer-list {
  @apply space-y-4;
}
