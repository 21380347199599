.search-bar {
  @apply flex items-center md-more:min-h-[4.375rem] sm:grid sm:gap-y-2.5 sm:text-center;
}

.search-bar-message {
  @apply title-5 text-brand1-500;
}

.search-bar-sort {
  @apply flex items-center ml-auto sm:mx-auto;

  .form-label {
    @apply m-0 mr-2 p-0 text-m text-body-300;
  }
}
