@import '../fonts/platform';

@config '../../../../tailwind/application/config.js';

:root {
  --breakpoint-2xl: theme(screens.2xl.max);
  --breakpoint-xl: theme(screens.xl.max);
  --breakpoint-lg: theme(screens.lg.max);
  --breakpoint-md: theme(screens.md.max);
  --breakpoint-sm: theme(screens.sm.max);
  --breakpoint-xs: theme(screens.xs.max);

  --container-columns: 12;
  --container-gutter: 2rem;
  --container-offset: 4rem;
  --container-width: theme(maxWidth.screen-2xl);
  --wrapper-width: min(theme(maxWidth.screen-default), 100vw);
  --wrapper-offset: calc(((var(--wrapper-width) - var(--container-width)) * 0.5) + var(--container-offset));
  --header-height: 7.875rem;
  --header-top-bar-height: 5.625rem;
  --scroll-padding-top: 10.875rem;

  @media screen(xl) {
    --container-offset: 3.5rem;
    --container-width: theme(maxWidth.screen-xl);
  }

  @media screen(lg) {
    --container-gutter: 1.5rem;
    --container-offset: 1.5rem;
    --container-width: theme(maxWidth.screen-lg);
    --header-height: 4.125rem;
    --scroll-padding-top: 8.125rem;
  }

  @media screen(md) {
    --container-gutter: 1.25rem;
    --container-width: 100vw;
  }

  @media screen(sm) {
    --container-columns: 4;
    --container-offset: 1rem;
    --scroll-padding-top: 6.125rem;
  }
}

:root, [data-theme="light"] {
  --theme-background-color: theme(colors.white);
  --theme-primary-color: theme(colors.brand1.500);
  --theme-secondary-color: theme(colors.brand2.500);
  --theme-text-color: theme(colors.body.500);
}

[data-theme="brand1"] {
  --theme-background-color: theme(colors.brand1.500);
  --theme-primary-color: theme(colors.white);
  --theme-secondary-color: theme(colors.white);
  --theme-text-color: theme(colors.white);
}

[data-theme="brand2"] {
  --theme-background-color: theme(colors.brand2.500);
  --theme-primary-color: theme(colors.white);
  --theme-secondary-color: theme(colors.white);
  --theme-text-color: theme(colors.white);
}

[class*="--theme"] {
  @apply transition-colors duration-500;
}

html {
  @apply antialiased;
}

body {
  @apply font-body text-l text-body-500;
}
