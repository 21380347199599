.wysiwyg {
  .wysiwyg-card {
    @apply space-y-6;

    .wysiwyg-lead {
      @apply text-brand3-500;
    }
  }

  .wysiwyg-card-image {
    @apply aspect-h-1 aspect-w-1 m-0 bg-brand2-100/50;

    img {
      @apply h-full !w-full object-cover;
    }
  }

  .wysiwyg-card-body {
    @apply space-y-4;
  }
}
