.wysiwyg {
  .wysiwyg-block-title {
    @apply text-brand1-500;
    font-size: 2.125rem;
    line-height: 1.3;

    @media screen(lg) {
      font-size: 1.875rem;
    }

    @media screen(sm) {
      font-size: 1.625rem;
    }
  }

  .wysiwyg-lead {
    @apply text-brand1-500;
    font-size: 1.5rem;
    line-height: 1.3;

    @media screen(sm) {
      font-size: 1.4375rem;
    }
  }
}
