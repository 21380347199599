.form-label {
  @apply text-left;

  :where(abbr) {
    @apply no-underline;
  }
}

.form-group {
  @apply col-span-12;

  &.form-group-invalid {
    .form-hint {
      @apply text-alert-500;
    }
  }
}

.form-hint {
  @apply mt-2 text-xs text-right text-body-300;
}

.form-group:where(
  :not(
    .form-group-boolean,
    .form-group-check-boxes,
    .form-group-file,
    .form-group-radio-buttons,
    .form-group-switch
  )
) {
  :where(.form-label) {
    @apply flex flex-wrap items-baseline gap-x-[0.1875rem] mb-2 px-3 text-s text-body-300;
  }

  :where(.form-field) {
    @apply relative flex gap-x-2;

    :where(.form-field-addon) {
      @apply flex-none;

      input, textarea, select, .form-input, .form-textarea, .form-select {
        @apply px-9 first:pl-3 last:pr-3;
      }
    }

    :where(.form-addon) {
      @apply flex-none grid place-items-center py-3 px-3.5 rounded-lg border border-brand6-400 bg-brand6-100 text-brand6-700 text-m;
    }
  }

  :where(.form-control) {
    @apply flex-1;

    :where(.form-icon, .form-btn) {
      @apply z-[2] absolute top-1/2 -translate-y-1/2 grid place-items-center h-6 w-6 text-body-200 text-center first:left-0 first:ml-3 last:right-0 last:mr-3;
    }

    :where(.form-icon) {
      @apply pointer-events-none;
    }
  }

  :where(input, textarea, select, .form-input, .form-textarea, .form-select) {
    @apply block w-full text-left px-11 first:pl-3 last:pr-3;
  }
}

.form-group-check-boxes,
.form-group-radio-buttons,
.form-group-file {
  :where(.form-label) {
    @apply text-s text-body-300;
  }
}

.form-group-check-boxes,
.form-group-radio-buttons {
  :where(.form-label) {
    @apply mb-3;
  }
}

.form-group-file {
  :where(.form-label) {
    @apply mb-3.5;
  }
}
