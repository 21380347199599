.search-container {
  @apply grid grid-cols-container gap-x-[--container-gutter] gap-y-8;
}

.search-side {
  @apply grid self-start gap-y-8 col-span-3 lg:col-span-4 md:col-span-12 sm:col-span-4;
}

.search-side-card {
  @apply min-w-0 px-4 py-6 bg-grey text-brand1-500;
}

.search-side-card-head {
  @apply grid grid-cols-[2.5rem_1fr] items-center gap-x-2;
}

.search-side-card-icon {
  @apply grid place-items-center h-10 w-10 rounded-full bg-white text-brand3-500;
}

.search-side-card-title {
  @apply title-5;
}

.search-main {
  @apply col-span-9 lg:col-span-8 md:col-span-12 sm:col-span-4;
}
