.ts-control {
  @apply min-h-[3.375rem];

  & > input {
    @apply w-auto text-m;
  }
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select,
.form-input,
.form-textarea,
.form-select,
.form-multiselect,
.ts-control {
  @apply bg-white border-body-100 px-3 py-[0.875rem] text-m text-body-500 rounded-lg focus:border-brand2-500 disabled:bg-grey;

  &.form-invalid {
    @apply border-alert-500;
  }
}

input, .form-input,
textarea, .form-textarea {
  &::placeholder {
    @apply text-body-200;
  }
}

select {
  &:where(.select-default) {
    @apply pr-11;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='8' fill='none' viewBox='0 0 14 8'%3E%3Cpath fill='%23ADADAD' fill-rule='evenodd' d='M.47.47a.75.75 0 0 1 1.06 0L7 5.94 12.47.47a.75.75 0 1 1 1.06 1.06l-6 6a.75.75 0 0 1-1.06 0l-6-6a.75.75 0 0 1 0-1.06Z' clip-rule='evenodd'/%3E%3C/svg%3E%0A");
    background-position: center right 17px;
    background-size: 14px 8px;
  }

  &:where(:not(.select-default)) {
    @apply bg-none disabled:opacity-100;
  }
}

[type='checkbox'], .form-checkbox {
  @apply block h-6 w-6 rounded-md;

  &, &:indeterminate, &:checked {
    &, &:hover, &:focus {
      @apply border-2 border-brand2-500 bg-white bg-center bg-[length:1rem_1rem] bg-no-repeat;
    }
  }

  &:indeterminate {
    background-image: url('application/icons/checkbox-indeterminate.svg');
  }

  &:checked {
    background-image: url('application/icons/checkbox-checked.svg');
  }

  &:disabled {
    @apply disabled:bg-brand2-200 disabled:border-brand2-200;
  }
}

[type='radio'], .form-radio {
  @apply block h-6 w-6 rounded-full;

  &, &:hover, &:focus {
    @apply border-2;

    &, &:checked {
      @apply border-brand2-500 bg-white bg-none;
    }
  }

  &:checked {
    &, &:hover, &:focus {
      @apply border-8;
    }
  }
}
