.title-1 {
  font-size: 4.25rem;
  line-height: 1.3;
  letter-spacing: normal;

  @media screen(sm) {
    font-size: 2.4375rem;
  }

  @layer utilities {
    &-default {
      font-size: 4.25rem;
      line-height: 1.3;
      letter-spacing: normal;
    }

    &-sm {
      font-size: 2.4375rem;
      line-height: 1.3;
      letter-spacing: normal;
    }
  }
}

.title-2 {
  font-size: 3.5rem;
  line-height: 1.3;
  letter-spacing: normal;

  @media screen(lg) {
    font-size: 2.75rem;
  }

  @media screen(sm) {
    font-size: 2rem;
  }

  @layer utilities {
    &-default {
      font-size: 3.5rem;
      line-height: 1.3;
      letter-spacing: normal;
    }

    &-lg, &-md {
      font-size: 2.75rem;
      line-height: 1.3;
      letter-spacing: normal;
    }

    &-sm {
      font-size: 2rem;
      line-height: 1.3;
      letter-spacing: normal;
    }
  }
}

.title-3 {
  font-size: 3rem;
  line-height: 1.3;
  letter-spacing: normal;

  @media screen(lg) {
    font-size: 2.25rem;
  }

  @media screen(sm) {
    font-size: 1.8125rem;
  }

  @layer utilities {
    &-default {
      font-size: 3rem;
      line-height: 1.3;
      letter-spacing: normal;
    }

    &-lg, &-md {
      font-size: 2.25rem;
      line-height: 1.3;
      letter-spacing: normal;
    }

    &-sm {
      font-size: 1.8125rem;
      line-height: 1.3;
      letter-spacing: normal;
    }
  }
}

.title-4 {
  font-size: 2.125rem;
  line-height: 1.3;

  @media screen(lg) {
    font-size: 1.875rem;
  }

  @media screen(sm) {
    font-size: 1.625rem;
  }

  @layer utilities {
    &-default {
      font-size: 2.125rem;
      line-height: 1.3;
    }

    &-lg, &-md {
      font-size: 1.875rem;
      line-height: 1.3;
    }

    &-sm {
      font-size: 1.625rem;
      line-height: 1.3;
    }
  }
}

.title-5 {
  font-size: 1.5rem;
  line-height: 1.3;

  @media screen(sm) {
    font-size: 1.4375rem;
  }

  @layer utilities {
    &-default {
      font-size: 1.5rem;
      line-height: 1.3;
    }

    &-sm {
      font-size: 1.4375rem;
      line-height: 1.3;
    }
  }
}

.title-6 {
  font-size: 1.25rem;
  line-height: 1.3;
  letter-spacing: normal;

  &-default {
    font-size: 1.25rem;
    line-height: 1.3;
  }
}

.caption {
  @apply italic;
  font-size: 1rem;
  letter-spacing: 0.02em;
  line-height: 1.5;
}
